import supplierRecallService from '../../../../../services/supplier/supplier-recall'
import {PRODUCT_DISRUPTION_TYPES } from '../../../../../misc/commons'
export default {
    props: ['communicationType'],
    data() {
        return {
            loadingInitial: true,
            mode: '',
            coveredListSearchQuery: {},
            locationsCoveredList: [],
            totalLocationsCovered: 0,
            locationsEmailedList: [],
            totalLocationsEmailed: 0,
            notCoveredListSearchQuery: {},
            locationsNotCoveredList: [],
            totalLocationsNotCovered: 0,
            unableToProcessedSearchQuery: {},
            locationsUnableToProcessedList: [],
            totalLocationsUnableToProcessed: 0,
            communicationId: null,
            fileId: null,
            locationHeader: [
                {
                    label: 'Account Name',
                    name: 'account_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Email Address',
                    name: 'email_address',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'First Name',
                    name: 'first_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Last Name',
                    name: 'last_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Account Number',
                    name: 'account_number',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'IDN',
                    name: 'idn',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                }
            ],

            unableProcessedLocationHeader: [
                {
                    label: 'Account Name',
                    name: 'account_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Email Address',
                    name: 'location_id',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'First Name',
                    name: 'location_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Last Name',
                    name: 'location_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'IDN',
                    name: 'address1',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                }
            ],
        }
    },
    computed: {
    },

    created() {

    },
    methods: {
        forceRerender(mode,communicationId, fileId) {
            this.mode = mode
            this.loadingInitial = true
            this.communicationId = communicationId
            this.fileId = fileId
            this.$refs.modal.show()
            this.loadingInitial = false
            if(this.mode == 'selectAll')
            {
                this.fetchAllLocationList()
            }
        },
        onClose() {
            this.$refs.modal.hide()
            this.$emit('onCompleted')
            this.$parent.fetchNotCoveredList()
            this.$parent.fetchCoveredList()
            this.$parent.fetchUnRecognisedList()
        },
        fetchAllLocationList() {
            supplierRecallService.locationsBycommunicationId({
                ...this.coveredListSearchQuery,
                communicationId: this.communicationId
            }).then(resp => {
                this.fetchValidList()
                this.fetchInvalidList()
            })
        },
        fetchValidList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.coveredListSearchQuery,
                sortField: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].name : null,
                sortDirection: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].order : null,
                communicationId: this.communicationId,
                type: 'VALID'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsCoveredList = resp.data.d.c
                    this.totalLocationsCovered = resp.data.d.t
                }
            })
        },
        fetchInvalidList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.coveredListSearchQuery,
                sortField: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].name : null,
                sortDirection: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].order : null,
                communicationId: this.communicationId,
                type: 'INVALID'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsEmailedList = resp.data.d.c
                    this.totalLocationsEmailed = resp.data.d.t
                }
            })
        },
        fetchUnableToProcessedList() {
            supplierRecallService.findLocationsBycommunicationIdAndFileId({
                ...this.unableToProcessedSearchQuery,
                sortField: this.unableToProcessedSearchQuery.sort[0] ? this.unableToProcessedSearchQuery.sort[0].name : null,
                sortDirection: this.unableToProcessedSearchQuery.sort[0] ? this.unableToProcessedSearchQuery.sort[0].order : null,
                communicationId: this.communicationId,
                fileId: this.fileId,
                type: 'VALID'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsUnableToProcessedList = resp.data.d.c
                    this.totalLocationsUnableToProcessed = resp.data.d.t
                }
            })
        },

        downloadFile(type) {
            supplierRecallService.downloadAffectedLocations({
                communicationId: this.communicationId,
                fileId: this.fileId,
                type
            }).then(resp => {
                if (!resp.error) {
                    window.open(resp.data.d, '_blank')
                }
            })
        }
    }
}